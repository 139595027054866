<template lang="pug">
  button.button(
    @click="$emit('click')"
    :class="[...takeModifierButton]"
  )
    span(
      v-if="text"
    ) {{text}}
    FontAwesomeIcon(
      v-if="icon"
      :icon="icon"
    )

</template>

<script>

export default {
  name: 'Button',
  props: {
    border: {
      type: String,
      required: false,
      validator: value =>
        ['base', 'bordless'].includes(value),
      default: () => 'bordless'
    },
    color: {
      type: String,
      required: false,
      validator: value =>
        ['blue', 'red'].includes(value),
      default: () => 'blue'
    },
    size: {
      type: String,
      required: false,
      validator: value =>
        ['normal', 'big'].includes(value),
      default: () => 'normal'
    },
    text: {
      type: String,
      required: false,
      default: () => 'text'
    },
    icon: {
      type: Array,
      required: false
    }
  },
  computed: {
    takeModifierButton () {
      const block = 'button'
      const classList = []
      if (this.border === 'bordless') {
        classList.push(`${block}--bordless`)
      } else {
        classList.push(`${block}--base`)
      }
      if (this.color === 'blue') {
        classList.push(`${block}--blue`)
      } else {
        classList.push(`${block}--red`)
      }
      if (this.size === 'big') {
        classList.push(`${block}--big`)
      }
      return classList
    }
  }
}
</script>

<style lang="sass" scoped>
  .button
    color: #287AED
    cursor: pointer
    flex-shrink: 0
    padding: 0
    font-size: 16px
    font-weight: 400
    &--bordless
      border: none
      background: none
    &--base
      border: 2px solid #287AED
      height: 40px
      background: none
      border-radius: 15px
      padding: 0 56px
    &--red
      border-color: #DD3434
      color: #DD3434
    &--normal
      font-size: 16px
      height: 16px
    &--big
      font-size: 18px
      height: 32px
      font-size: 20px

</style>
